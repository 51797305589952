<template>
  <v-autocomplete
    v-model="chosenInsurer"
    v-bind="$attrs"
    :items="formattedInsurers"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
    small-chips
    clearable
  />
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "InsurerPicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data: () => ({
    chosenInsurer: null,
    totalRecords: 0,
    isLoading: false,
    returnedInsurers: [],
    formattedInsurers: [],
  }),
  apollo: {
    insurers: {
      query: gql`
        query Insurers($limit: Int!, $skip: Int!, $query: JSON!) {
          insurers(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              name
            }
            count
          }
        }
      `,
      variables() {
        return {
          limit: 9999,
          skip: 0,
          query: {},
        };
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      result({ data }) {
        this.returnedInsurers = data.insurers.records;
        this.formattedInsurers = this.returnedInsurers.map((insurer) => ({
          text: insurer.name,
          value: insurer.id,
        }));

        // Set initial value if provided and matches any of the items
        if (this.initial) {
          const matchingInsurer = this.formattedInsurers.find(
            (item) => item.value === this.initial
          );
          if (matchingInsurer) {
            this.chosenInsurer = this.initial;
          }
        }
      },
    },
  },
  watch: {
    initial(newValue) {
      const matchingInsurer = this.formattedInsurers.find(
        (item) => item.value === newValue
      );
      if (matchingInsurer) {
        this.chosenInsurer = newValue;
      }
    },
    chosenInsurer(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("update:chosen-insurer", newValue ? newValue : null);
      }
    },
  },
  mounted() {
    // Ensure the initial value is set if it's defined and matches an item
    if (this.initial) {
      const matchingInsurer = this.formattedInsurers.find(
        (item) => item.value === this.initial
      );
      if (matchingInsurer) {
        this.chosenInsurer = this.initial;
      }
    }
  },
};
</script>

<style lang="sass"></style>
