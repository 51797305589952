<template>
  <base-material-card
    class="mt-4"
    title="Voicemail per Consultant"
    color="'#0a2752'"
    :elevation="8"
  >
    <bar
      style="height: 45vh"
      :chart-data="userEvents"
      :bind="true"
      :options="{
        responsive: true,
        maintainAspectRatio: false,
        legend: { position: 'bottom' },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                color: 'rgba(0, 0, 0, 0)',
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                color: 'rgba(0, 0, 0, 0)',
              },
            },
          ],
        },
      }"
    />
  </base-material-card>
</template>

<script>
import { gql } from "apollo-boost";
import _ from "lodash"; // Import lodash for grouping and mapping
import Bar from "../Bar.vue";

export default {
  name: "VoicemailEventsPerConsultantGraph",
  components: { Bar },
  apollo: {
    me: gql`
      query {
        me {
          id
          email
          role
        }
      }
    `,
    users: {
      query: gql`
        query Users($limit: Int!, $page: Int!, $query: JSON!) {
          users(limit: $limit, page: $page, query: $query) {
            records {
              id
              consultant
              progress
            }
          }
        }
      `,
      variables() {
        const limit = 100000;
        const page = 1; // Setting page instead of skip
        var query = { progress: "Voicemail" }; // Filter for Voicemail progress
        return {
          limit: limit,
          page: page, // Using page here
          query: query,
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result(data, key) {
        var userRecords = data.data.users.records;
        var groupedByConsultant = _.groupBy(userRecords, "consultant");
        console.log(groupedByConsultant);
        this.usersGroupedByConsultant = groupedByConsultant;
      },
    },
  },
  data: () => ({
    selected: "",
    users: "",
    usersGroupedByConsultant: [],
  }),
  computed: {
    labels() {
      return _.map(this.usersGroupedByConsultant, (item, index) => {
        if (index !== "null") {
          return index;
        }
        return "Not Assigned";
      });
    },
    chartdata() {
      return _.map(this.usersGroupedByConsultant, (item) => {
        return item.length;
      });
    },
    userEvents() {
      // Return the expected structure for Bar.vue component
      return _.map(this.labels, (label, index) => {
        return { x: label, y: this.chartdata[index] };
      });
    },
  },
  async mounted() {
    // You can add a console.log here to debug the structure of userEvents
    console.log("userEvents:", this.userEvents);
  },
  methods: {
    getAssignedColors() {
      const primaryColor = "#fab71d";
      const secondaryColor = "#1c3055";
      // Generate colors based on the length of usersGroupedByConsultant
      return _.map(this.labels, (item, index) => {
        // Alternate between primary and secondary colors
        return index % 2 === 0 ? primaryColor : secondaryColor;
      });
    },
  },
};
</script>

<style lang="sass"></style>
