<template>
  <base-material-card
    class="mt-4"
    title="Open Events"
    color="'#0a2752'"
    :elevation="8"
  >
    <bar
      style="height: 45vh"
      :chart-data="userEvents"
      :bind="true"
      :options="{
        responsive: true,
        maintainAspectRatio: false,
        legend: { position: 'bottom' },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                color: 'rgba(0, 0, 0, 0)',
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                color: 'rgba(0, 0, 0, 0)',
              },
            },
          ],
        },
      }"
    />
  </base-material-card>
</template>

<script>
import { gql } from "apollo-boost";
import Bar from "../Bar.vue";
export default {
  name: "OpenEventsGraph",
  components: { Bar },
  apollo: {
    me: gql`
      query {
        me {
          id
          email
          role
        }
      }
    `,
    events: {
      query: gql`
        query Events($limit: Int!, $skip: Int!, $query: JSON!) {
          events(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              type
              comment
              user
              status
              createdAt
              updatedAt
            }
            count
          }
        }
      `,
      variables() {
        const limit = 100000;
        const skip = 0;
        var query = { status: "open" };
        return {
          limit: limit,
          skip: skip,
          query: query,
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result(data, key) {
        var eventRecords = data.data.events.records;
        var groupedByUser = _.groupBy(eventRecords, "type");
        console.log(groupedByUser);
        this.eventsGroupedByUser = groupedByUser;
      },
    },
  },
  data: () => ({
    selected: "",
    events: "",
    eventsGroupedByUser: [],
  }),
  computed: {
    labels() {
      return _.map(this.eventsGroupedByUser, (item, index) => {
        if (index !== "null") {
          return index;
        }
        return "Not Assigned";
      });
    },
    chartdata() {
      return _.map(this.eventsGroupedByUser, (item, index) => {
        return item.length;
      });
    },
    userEvents() {
      return {
        labels: this.labels,
        datasets: [
          {
            label: "Event types vs count",
            borderColor: "#888",
            backgroundColor: this.getRandomColors(),
            borderWidth: 2,
            data: this.chartdata,
          },
        ],
      };
    },
  },
  async mounted() {},
  methods: {
    getRandomColors() {
      return _.map(this.eventsGroupedByUser, (item) => {
        var letters = "0123456789ABCDEF".split("");
        var color = "#";
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      });
    },
  },
};
</script>

<style lang="sass"></style>
