<template>
  <v-autocomplete
    v-model="chosenUser"
    v-bind="$attrs"
    :items="formattedUsers"
    small-chips
    clearable
    outlined
    @change="emitConsultantId"
  />
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "SimpleConsultantPicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data: () => ({
    chosenUser: null,
    totalRecords: 0,
    isLoading: false,
    returnedUsers: [],
    formattedUsers: [],
  }),
  apollo: {
    users: {
      query: gql`
        query Users($limit: Int, $query: JSON!) {
          users(limit: $limit, query: $query) {
            records {
              id
              firstName
              lastName
              role
              branch
              branchName
            }
          }
        }
      `,
      variables() {
        return {
          limit: 9999,
          query: { role: "consultant" },
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result({ data }, key) {
        this.returnedUsers = data.users.records;

        this.formattedUsers = _.map(this.returnedUsers, (user) => {
          return {
            text: `${user.firstName} ${user.lastName}- from ${user.branchName}`,
            value: `${user.firstName} ${user.lastName}`,
          };
        });
      },
    },
  },

  async mounted() {
    this.$apollo.queries.users.refetch();
  },
  methods: {
    emitConsultantId() {
      this.$emit("consultant-selected", this.chosenUser);
    },
  },
};
</script>

<style lang="sass"></style>
