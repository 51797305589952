<template>
  <v-card>
    <v-card-title>Single Lead Metrics</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-card class="mx-auto my-8" elevation="16" max-width="344">
            <v-card-item>
              <v-card-title> Leads Allocated </v-card-title>
            </v-card-item>

            <v-card-text>
              {{ userStats.leadsAllocated }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card class="mx-auto my-8" elevation="16" max-width="344">
            <v-card-item>
              <v-card-title> Contacted Leads </v-card-title>
            </v-card-item>

            <v-card-text>
              {{ userStats.contactedLeads }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card class="mx-auto my-8" elevation="16" max-width="344">
            <v-card-item>
              <v-card-title> Quotes done</v-card-title>
            </v-card-item>

            <v-card-text>
              {{ userStats.quotesDone }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card class="mx-auto my-8" elevation="16" max-width="344">
            <v-card-item>
              <v-card-title> Sales Done </v-card-title>
            </v-card-item>

            <v-card-text>
              {{ userStats.salesDone }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card class="mx-auto my-8" elevation="16" max-width="344">
            <v-card-item>
              <v-card-title> Conversion Ratio</v-card-title>
            </v-card-item>

            <v-card-text>
              {{ userStats.conversionRatio }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      userStats: {
        leadsAllocated: 120,
        contactedLeads: 80,
        quotesDone: 40,
        salesDone: 20,
        conversionRatio: ((20 / 120) * 100).toFixed(2),
      },
    };
  },
};
</script>
