<template>
  <v-app v-if="me">
    <v-app-bar app dark color="#363636">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title v-if="user" dark class="white--text"
        >{{ user.firstName }} {{ user.lastName }} from branch
        {{ user.branchName }}</v-toolbar-title
      >
      <v-spacer></v-spacer>

      <v-menu
        offset-y
        bottom
        transition="scale-transition"
        :close-on-content-click="false"
        min-width="280"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon dark outlined v-bind="attrs" v-on="on" class="mr-6">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <v-list class="search">
          <!-- :uses="users"  -->
          <div class="search">
            <base-user-picker
              label="Search"
              placeholder="Search by ID Number,Name or email"
              outlined
              clearable
            />
          </div>
        </v-list>
      </v-menu>
      <v-menu
        offset-y
        bottom
        transition="scale-transition"
        :close-on-content-click="false"
        min-width="280"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon dark outlined v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="logout" class="mb-3">
            <v-list-item-icon>
              <v-icon>mdi-location-exit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      id="core-navigation-drawer"
      app
      dark
      mobile-breakpoint="960"
      v-bind:width="280"
    >
      <!------------------------------------------------------------------>
      <!--v-list-item -->
      <!-- //Medsafu HeadOffice 60f67355297f461ee095764b -->
      <!--v-list-item-content -->
      <div>
        <v-img
          @click="$router.push('/')"
          v-if="me.branch === '60f67355297f461ee095764b'"
          :contain="false"
          max-height="150px"
          class="py-1"
          v-bind="props"
          position="50% 50%"
          src="@/assets/img/full_logo_white.png"
        ></v-img>
        <!--/v-list-item-content-->
        <!-- // 1 call fs 60f6736e297f461ee095764c -->
        <!-- <v-list-item-content
          
        > -->
        <v-img
          @click="$router.push('/')"
          v-else-if="me.branch === '60f6736e297f461ee095764c'"
          :contain="false"
          max-height="150px"
          v-bind="props"
          position="50% 50%"
          class="py-1"
          src="@/assets/img/1calllogo.png"
        ></v-img>
        <!-- </v-list-item-content> -->
        <!-- //Med safu Sinoville 60f673fb297f461ee095764e -->
        <!-- <v-list-item-content
          v-else-if="me.branch === '60f673fb297f461ee095764e'"
        > -->
        <v-img
          @click="$router.push('/')"
          v-else-if="me.branch === '60f673fb297f461ee095764e'"
          :contain="false"
          max-height="150px"
          v-bind="props"
          position="50% 50%"
          class="py-1"
          src="@/assets/img/full_logo_white.png"
        ></v-img>
        <!-- </v-list-item-content> -->
        <!-- <v-list-item-content v-else> -->
        <v-img
          v-else
          @click="$router.push('/')"
          :contain="false"
          max-height="150px"
          v-bind="props"
          position="50% 50%"
          class="py-1"
          src="@/assets/img/1calllogo.png"
        ></v-img>
        <!-- </v-list-item-content>
      </v-list-item> -->
      </div>
      <v-divider></v-divider>
      <v-list nav>
        <div v-for="item in items" :key="item.title">
          <v-list-item v-if="!item.children" link :to="item.href">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-else
            :key="item.title"
            link
            :to="item.href"
            :value="false"
            prepend-icon="mdi-account-group-outline"
          >
            <template v-slot:activator>
              <v-list-item-title>Accounts</v-list-item-title>
            </template>

            <!---->
            <v-list-item
              v-for="item in item.children"
              :key="item.title"
              link
              :to="item.href"
            >
              <v-list-item-icon>
                <v-icon class="ml-3">{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!---->
          </v-list-group>
        </div>
      </v-list>
      <!------------------------------------------------------------------>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <v-col>
          <transition name="fade">
            <router-view />
          </transition>
        </v-col>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { gql } from "apollo-boost";
import { User } from "@/models/User";

export default {
  name: "AdminLayout",

  data() {
    return {
      props: {
        expandOnHover: {
          type: Boolean,
          default: false,
        },
      },
      drawer: null,
      me: null,
      user: null,
      items: [
        {
          title: "Reporting",
          icon: "mdi-file-chart",
          href: "/admin/dashboard",
        },

        {
          title: "Sales",
          icon: "mdi-cart-percent",
          href: "/admin/sales",
        },

        {
          title: "Commission",
          icon: "mdi-percent-circle",
          href: "/admin/commission",
        },

        {
          title: "Accounts",
          icon: "mdi-account-group-outline",
          href: "#",
          children: [
            {
              title: "Leads",
              icon: "mdi-account-filter-outline",
              href: "/admin/leads",
            },

            {
              title: "Existing Clients",
              icon: "mdi-account-multiple-plus-outline",
              href: "/admin/users",
            },
            {
              title: "Archived",
              icon: "mdi-account-filter-outline",
              href: "/admin/archived",
            },
            {
              title: "Consultants",
              icon: "mdi-account",
              href: "/admin/consultants",
            },
            {
              title: "Admins",
              icon: "mdi-shield-crown",
              href: "/admin/admins",
            },
          ],
        },
        {
          title: "Companies",
          icon: "mdi-book-check",
          href: "/admin/companies",
        },
        // {
        //   title: "Tasks",
        //   icon: "mdi-book-check",
        //   href: "/admin/tasks",
        // },
        {
          title: "Documents",
          icon: "mdi-file",
          href: "/admin/documents",
        },
        {
          title: "Products",
          icon: "mdi-clipboard-search-outline",
          href: "/admin/products",
        },

        {
          title: "Insurers",
          icon: "mdi-domain",
          href: "/admin/insurers",
        },
        {
          title: "Lead Source",
          icon: "mdi-book-check",
          href: "/admin/referrals",
        },
        {
          title: "Branches",
          icon: "mdi-family-tree",
          href: "/admin/branches",
        },
      ],
      right: null,
    };
  },
  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
            role
            branch
            branchName
            firstName
            lastName
          }
        }
      `,
      fetchPolicy: "network-only",
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        if (response.data) {
          this.user = new User();
          this.user.id = this.me.id;
          this.user.role = this.me.role;
          this.user.firstName = this.me.firstName;
          this.user.lastName = this.me.lastName;
          this.user.branch = this.me.branch;
          this.user.branchName = this.me.branchName;
        }
      },
      error(error) {
        this.errorMsg = error;
      },
    },
  },
  methods: {
    myFunction: function () {
      window.open(
        "https://media.publit.io/file/medsafu/HelpDocs/CRMAdminHelpdocument.pdf",
        "_blank"
      );
    },
    logout() {
      localStorage.setItem("token", "");
      localStorage.setItem("role", "");
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  padding: 12px 0px;
  margin-top: 0 !important;
  position: relative;
  overflow: visible;
  z-index: 100;
}
.help-btn:hover .help-text {
  display: inline-block;
  transition: all 2s linear;
}
.help-text {
  display: none;
}
button.float-right.help-btn.v-btn.v-btn--absolute.v-btn--bottom.v-btn--is-elevated.v-btn--has-bg.v-btn--rounded.theme--dark.v-size--default.primary {
  float: right;
  right: 1%;
}
</style>
