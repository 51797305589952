import gql from "graphql-tag";

export const CREATE_AND_SEND_REPORT = gql`
  mutation CreateAndSendReport(
    $start: String!
    $end: String!
    $source: String!
    $leadSummary: [JSON]
    $salesConversion: [JSON]
    $salesSummary: [JSON]
    $type: String
  ) {
    createAndSendReport(
      start: $start
      end: $end
      source: $source
      leadSummary: $leadSummary
      salesConversion: $salesConversion
      salesSummary: $salesSummary
      type: $type
    ) {
      success
      message
    }
  }
`;

export const GET_USERS = gql`
  query Users($limit: Int, $page: Int, $query: JSON!) {
    users(limit: $limit, page: $page, query: $query) {
      records {
        id
        firstName
        lastName
        email
        fullAddress
        telNumber
        altNumber
        branch
        referral
        referralNumber
        title
        consultant
        consultantName
        company
        companyName
        dob
        consultantName
        leadDescription
        source
        progress
        branchName
        role
        bio
        brokerCommission
        createdAt
        updatedAt
      }
      paginator {
        userCount
        perPage
        pageCount
        currentPage
        slNo
        hasNextPage
        hasPrevPage
        next
        prev
      }
    }
  }
`;

export const GET_USERS_SMALL = gql`
  query Users($limit: Int, $page: Int, $query: JSON!) {
    users(limit: $limit, page: $page, query: $query) {
      records {
        id
        firstName
        lastName
        email
      }
      paginator {
        userCount
        perPage
        pageCount
        currentPage
        slNo
        hasNextPage
        hasPrevPage
        next
        prev
      }
    }
  }
`;

export const GET_CONSULTANTS = gql`
  query Users($limit: Int, $page: Int, $query: JSON!) {
    users(limit: $limit, page: $page, query: $query) {
      records {
        id
        firstName
        lastName
        role
        consultant
        consultantName
        branch
        branchName
        email
        telNumber
        createdAt
        updatedAt
      }
      paginator {
        userCount
        perPage
        pageCount
        currentPage
        slNo
        hasNextPage
        hasPrevPage
        next
        prev
      }
    }
  }
`;

export const UPDATE_ALERT_MESSAGE = gql`
  mutation UpdateAlertMessage($docId: String!) {
    updateAlertMessage(docId: $docId)
  }
`;

export const GET_KANBAN_TASKS = gql`
  query kanbanTasks($limit: Int!, $skip: Int!, $query: JSON!) {
    kanbanTasks(limit: $limit, skip: $skip, query: $query) {
      records {
        id
        user
        assignedTo
        status
        read
        description
        deadline
        showAlertMessage
        assignedToName
        assignedToEmail
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const GET_USER_PRODUCTS = gql`
  query UserProducts($limit: Int!, $skip: Int!, $query: JSON!) {
    userProducts(limit: $limit, skip: $skip, query: $query) {
      records {
        id
        user
        product
        productName
        policyNumber
        documents {
          id
          name
          documentLink
          publitoDownloadUrl
          publitoEmbeddUrl
          publitoUrl
        }
        membershipNumber
        schemeJoin
        benefitDate
        dateResigned
        dependantCode
        beneficiaryType
        lateJoinerPenalty
        totalContrib
        inceptionDate
        startDate
        cancelDate
        productStatus
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const GET_CONSULTANTS_REPORT_DATA = gql`
  query ConsultantsProgressReport(
    $source: String
    $startDate: String
    $endDate: String
  ) {
    consultantsProgressReport(
      source: $source
      startDate: $startDate
      endDate: $endDate
    ) {
      consultantName
      source
      progressCounts {
        progress
        count
      }
    }
  }
`;
