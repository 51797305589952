<template>
  <v-card class="pa-4">
    <v-card-title class="text-h5 font-weight-bold"
      >Company Metrics</v-card-title
    >
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="3">
          <v-card
            class="mx-auto py-6"
            elevation="12"
            max-width="400"
            color="#1976D2"
            dark
          >
            <v-card-title class="white--text d-flex align-center">
              <v-icon left size="36">mdi-account-multiple</v-icon>
              Leads Allocated
            </v-card-title>
            <v-card-text class="text-center text-h3 white--text">
              {{ companyStats.leadsAllocated }}
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="6" lg="3">
          <v-card
            class="mx-auto py-8"
            elevation="12"
            max-width="400"
            color="#388E3C"
            dark
          >
            <v-card-title class="white--text d-flex align-center">
              <v-icon left size="36">mdi-phone</v-icon>
              Contacted Leads
            </v-card-title>
            <v-card-text class="text-center text-h3 white--text">
              {{ companyStats.contactedLeads }}
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="6" lg="3">
          <v-card
            class="mx-auto py-8"
            elevation="12"
            max-width="400"
            color="#FFA000"
            dark
          >
            <v-card-title class="white--text d-flex align-center">
              <v-icon left size="36">mdi-file-document</v-icon>
              Quotes Done
            </v-card-title>
            <v-card-text class="text-center text-h3 white--text">
              {{ companyStats.quotesDone }}
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="6" lg="3">
          <v-card
            class="mx-auto py-8"
            elevation="12"
            max-width="400"
            color="#D32F2F"
            dark
          >
            <v-card-title class="white--text d-flex align-center">
              <v-icon left size="36">mdi-currency-usd</v-icon>
              Sales Done
            </v-card-title>
            <v-card-text class="text-center text-h3 white--text">
              {{ companyStats.salesDone }}
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="6" lg="3">
          <v-card
            class="mx-auto py-8"
            elevation="12"
            max-width="400"
            color="#512DA8"
            dark
          >
            <v-card-title class="white--text d-flex align-center">
              <v-icon left size="36">mdi-percent</v-icon>
              Conversion Ratio
            </v-card-title>
            <v-card-text class="text-center text-h3 white--text">
              {{ companyStats.conversionRatio }}%
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      companyStats: {
        leadsAllocated: 2000,
        contactedLeads: 1500,
        quotesDone: 900,
        salesDone: 600,
        conversionRatio: ((600 / 2000) * 100).toFixed(2),
      },
    };
  },
};
</script>

<style>
.v-card-title .v-icon {
  margin-right: 8px;
}
</style>
